<template>
  <div class="page-bg">
    <div class="demo-main">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/demo' }">Demo</el-breadcrumb-item>
        <el-breadcrumb-item>Story retelling assessment</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">Speech Assessment of Story retelling</div>
      <div class="sub-title">Listen to the short passage, and then retell it within 60 seconds.</div>
      <div v-if="startSoe" class="countdown">{{ second }}s</div>
      <div class="demo-container">
        <div class="ref-text-part">
          <div class="ref-text-content">
            <div class="ref-text">
              <div class="question-stem">
                <p class="auditionBox">
                  <span>Short passage playback: </span>
                  <img v-if="!ifplayAudio" @click="playAudio" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
                  <img v-if="ifplayAudio" @click="stopAudio" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
                  <audio ref="audioElement" @ended="onAudioEnded" :src="subject[stemNum].questionAudio"></audio>
                </p>
                <div class="stem-item">
                  <p><span>Tip:&nbsp;&nbsp;</span>{{ subject[stemNum].tips }}</p>
                </div>
                <div class="question-item">
                  <el-button v-if="!openAnswer" @click="openAnswer = true" type="text">Check the Reference Answer <i class="el-icon-caret-bottom"></i></el-button>
                  <el-button v-if="openAnswer" @click="openAnswer = false" type="text">Collapse the Reference Answer <i class="el-icon-caret-top"></i></el-button>
                  <p v-if="openAnswer">{{ subject[stemNum].answer[0].text }}</p>
                </div>
                <div v-if="!startSoe" class="change-stem">
                  <el-button @click.stop="$event.preventDefault();stemNum--;hasResult = false;ifplayAudio = false;" type="text" size="medium" :disabled="stemNum===0"><i class="el-icon-arrow-left"></i>Previous</el-button>
                  <el-button @click.stop="$event.preventDefault();stemNum++;hasResult = false;ifplayAudio = false;" type="text" size="medium" :disabled="stemNum===4">Next<i class="el-icon-arrow-right"></i></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-recorder">
          <div @click="startEvaluation" v-if="recorderStatus === 1" class="recorder-box"><img src="~@/assets/images/soe_demo/demo/reg_start.png" alt=""></div>
          <div v-if="recorderStatus === 0" class="recorder-box recorder-box-loading">
            <ul class="loading-taste-line">
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
            </ul>
          </div>
          <div @click="stopEvaluation" v-if="recorderStatus === 2" class="recorder-box-stop">
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
            <div class="recorder-stop">
              <span></span>
            </div>
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
          </div>
        </div>
      </div>
      <el-divider v-if="hasResult"></el-divider>
      <div v-if="hasResult" class="result-detail">
        <div class="result-overall">
          <div class="text">Overall</div>
          <div class="play-replay">
            <span>{{ overall }}</span>
            <img v-if="!ifplayAudio_replay" @click="playAudio_replay" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
            <img v-if="ifplayAudio_replay" @click="stopAudio_replay" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
            <audio ref="audioElement_replay" @ended="onAudioEnded_replay" :src="audioUrl"></audio>
          </div>
        </div>
        <div class="more-score">
          <div class="score-item">
            <div class="score-name">Fluency</div>
            <div class="score-value">{{ fluency }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Integrity</div>
            <div class="score-value">{{ integrity }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Accuracy</div>
            <div class="score-value">{{ accuracy }}</div>
          </div>
        </div>
        <div class="answer-item">
          <div class="part-title">Reference answer</div>
          <ul>
            <li v-for="(item,index) in answer" :key="index">{{ item.text }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Phoneme',
  data () {
    return {
      soe: null,

      openAnswer: false,
      ifplayAudio: false,
      ifplayAudio_replay: false,

      recorderStatus: 1,

      startSoe: false,
      second: 60,
      loanTime: '',

      stemNum: 0,
      subject: [
        {
          index: 0,
          questionAudio: "https://smart-speech.com/res/miniprogram/en/retell/1.mp3",
          tips: "Terry has three good friends and they're all 12 years...",
          answer: [{
            text: "Terry has three good friends and they're all 12 years old. They have different hobbies. His friend Eric likes reading books. His friend Sally likes dancing very much. As for John, he enjoys drawing pictures. Terry's hobby is playing basketball."
          }],
          keyword: [{
            text: 'good friends'
          },{
            text: 'hobby'
          },{
            text: 'read books'
          },{
            text: 'dancing'
          },{
            text: 'draw pictures'
          },{
            text: 'play basketball'
          }]
        },{
          index: 1,
          questionAudio: "https://smart-speech.com/res/miniprogram/en/retell/2.mp3",
          tips: "Today is Tree Planting Day. Bob and his classmates...",
          answer: [{
            text: "Today is Tree Planting Day. Bob and his classmates are planting trees in the park near their school now. Bob is digging a hole. Amy is putting a tree into a hole. Susan is watering a tree."
          }],
          keyword: [{
            text: 'tree planting day'
          },{
            text: 'plant tree'
          },{
            text: 'dig a hole'
          },{
            text: 'put a tree'
          },{
            text: 'water a tree'
          }]
        },{
          index: 2,
          questionAudio: "https://smart-speech.com/res/miniprogram/en/retell/3.mp3",
          tips: "Bill visited Tim with his mother last weekend. Tim hurt his...",
          answer: [{
            text: "Bill visited Tim with his mother last weekend. Tim hurt his legs badly in an accident, so he has difficulty moving. They bought some presents for him, such as books and toys. Tim likes music and his dream is to be a musician. Bill hopes to help more children like Tim and raise their spirits."
          }],
          keyword: [{
            text: 'visit Tim'
          },{
            text: 'hurt his leg'
          },{
            text: 'bought some presents'
          },{
            text: 'to be a musician'
          },{
            text: 'raise their spirits'
          }]
        },{
          index: 3,
          questionAudio: "https://smart-speech.com/res/miniprogram/en/retell/4.mp3",
          tips: "Lily got a book named Dao De Jing. She got it...",
          answer: [{
            text: "Lily got a book named Dao De Jing. She got it as a gift from her uncle last week. The writer of the book is Lao Zi. He was a wise man in history. The book is popular all over the world. In the book, many people have found inspiration. Lily likes the book and thinks she can learn a lot of lessons about life from the book."
          }],
          keyword: [{
            text: 'Dao De Jing'
          },{
            text: 'as a gift'
          },{
            text: 'Lao Zi'
          },{
            text: 'over the world'
          },{
            text: 'inspiration'
          },{
            text: 'learn a lot of'
          }]
        },{
          index: 4,
          questionAudio: "https://smart-speech.com/res/miniprogram/en/retell/5.mp3",
          tips: "Lily listened to a speech last Saturday. A man from the World Wide Fund for Nature gave...",
          answer: [{
            text: "Lily listened to a speech last Saturday. A man from the World Wide Fund for Nature gave the speech. The World Wide Fund for Nature is an organization and it looks after endangered animals. It has close to five million members in around 100 countries. There are thousands of endangered animals around the world."
          }],
          keyword: [{
            text: 'speech'
          },{
            text: 'World Wide Fund'
          },{
            text: 'organization'
          },{
            text: 'endangered animals'
          },{
            text: 'five million members'
          },{
            text: 'thousands of'
          },{
            text: 'around the world'
          }]
        }
      ],

      hasResult: false,

      overall: '',
      fluency: '',
      integrity: '',
      accuracy: '',
      answer: [],
      keyword: [],
      audioUrl: '',
    }
  },
  mounted() {
    this.soe = new this.$SoeEngine({
      preService: this.$globalParam.preService,
      log: this.$globalParam.log,
      appId: this.$globalParam.appId,
      appSecret: this.$globalParam.appSecret,
      coreType: {
        langType: 'en-US',
        format: 'mp3', // 实时录音时可不传值；上传文件时传音频格式，支持 mp3、wav、pcm
        sampleRate: 16000, // 音频采样率（Hz）：当前仅支持 16000
        looseness: 4, // 评分宽松度，范围：0-9，数值越大越宽松
        connectTimeout: 15, // 连接超时时间（秒），范围：5-60
        responseTimeout: 15, // 响应超时时间（秒），范围：5-60
        scale: 100, // 评分分制，范围：1-100
        ratio: 1, // 评分调节系数，范围：0.8-1.5
        userId: '',
        audioUrl: true,
      },
      params: {
        mode: "", // 评测模式：基础题型 word/sentence/chapter  高阶题型 qa/topic/retell
        // 基础题型（词、句、篇章）
        refText: "", // 测评对照阅读的文本：
        // 高阶题型（问答题）
        stem: { // 题干
          para: "", // 段落内容
          question: "", // 提问内容
        },
        distractor: [],
        // 高阶题型（问答题）&& 高阶题型（看图说话/口语作文）&& 高阶题型（复述）
        answer: [],
        keyword: [],
      },
      micAllowCallback: () => {
        console.log('=====The microphone has been allowed.=====');
      },
      micForbidCallback: (msg) => {
        console.error('====='+msg+'=====');
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(msg)
      },
      engineFirstInitDone: () => {
        console.log('=====Initialization successful.=====');
      },
      engineFirstInitFail: (status,msg) => {
        console.error('====='+status+':'+msg+'=====');
        this.recorderStatus = 1
        this.$Toast(status+':'+msg)
      },
      getStarted: () => {
        this.recorderStatus = 2
        this.loanTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.loanTime)
            this.loanTime = ''
            this.second = 60
            this.stopEvaluation()
          }
        }, 1000)
      },
      engineBackResultDone: (msg) => {
        this.recorderStatus = 1
        console.log(msg);
        this.getResult(msg)
      },
      engineBackResultWarning: (status,msg) => {
        console.log(status,msg)
      },
      engineBackResultError: (status,msg) => {
        console.log(status,msg)
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(status+':'+msg)
      },
      playAudioComplete: () => {
        console.log('Playback complete.');
      },
      playAudioError: () => {
        this.recorderStatus = 1
        this.$Toast("Playback error")
      },
      micVolumeCallback: (data) => {
        console.log('Recording volume level is:' + data);
      },
      noNetwork: () => {
        this.recorderStatus = 1
        this.$Toast("No network available")
      },
      recorderBlob: (blob) => {
        console.log(blob)
      },
    })
  },
  methods: {
    // 试听音频播放
    playAudio() {
      const audioElement = this.$refs.audioElement;
      audioElement.play();
      this.ifplayAudio = true
    },
    stopAudio() {
      const audioElement = this.$refs.audioElement;
      audioElement.pause();
      audioElement.currentTime = 0;
      this.ifplayAudio = false
    },
    onAudioEnded() {
      this.ifplayAudio = false
    },

    // 开始评测
    startEvaluation() {
      this.recorderStatus = 0
      this.soe.params.mode = "retell";
      this.soe.params.answer = []
      this.soe.params.keyword = []
      this.soe.params.answer = this.subject[this.stemNum].answer
      this.soe.params.keyword = this.subject[this.stemNum].keyword
      let params = this.soe.params;
      this.soe.startRecord(params);
      this.startSoe = true
    },
    // 结束评测
    stopEvaluation() {
      clearInterval(this.loanTime)
      this.loanTime = ''
      this.second = 60
      this.soe.stopRecord();
      this.startSoe = false
    },
    // 处理评测结果
    getResult(result) {
      console.log(result)
      this.overall = (result.overall).toFixed(0)
      this.fluency = (result.fluency).toFixed(0)
      this.integrity = (result.integrity).toFixed(0)
      this.accuracy = (result.accuracy).toFixed(0)
      this.answer = result.params.request.params.answer
      this.keyword = result.params.request.params.keyword

      this.audioUrl = result.audioUrl
      this.hasResult = true
    },
    playAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.play();
      this.ifplayAudio_replay = true
    },
    stopAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.pause();
      audioElement_replay.currentTime = 0;
      this.ifplayAudio_replay = false
    },
    onAudioEnded_replay() {
      this.ifplayAudio_replay = false
    },
  }
}
</script>

<style scoped lang="less">
.page-bg {
  display: flex;
  padding: 30px 0;
}
.demo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 85%;
  padding: 60px 10px 30px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .breadcrumb {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-bottom: 30px;
  }
  .title {
    line-height: 30px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  .sub-title {
    line-height: 28px;
    margin: 15px auto 0;
    color: #333;
    text-align: center;

    font-size: 16px;
  }
  .countdown {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 100%;
    background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
    color: #fff;
  }
  .demo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 95%;
    padding: 20px 0 0;
    .ref-text-part {
      width: 100%;
    }
    .ref-text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      .ref-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        font-weight: 500;
        font-size: 26px;
        color: #333;
        .question-stem {
          width: 100%;
          .auditionBox {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-size: 14px;
            img {
              width: 30px;
              margin-left: 10px;
              cursor: pointer;
            }
          }
          .stem-item {
            p {
              display: flex;
              align-items: baseline;
              margin-top: 5px;
              font-size: 16px;
              span {
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
          .question-item {
            p:first-child {

              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
          }
          .change-stem {
            text-align: right;
            .el-button {
              margin: 0 10px;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      }
    }
    .btn-recorder {
      position: relative;
      height: 80px;
      margin-top: 23px;
      cursor: pointer;
      .recorder-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
        box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
        cursor: pointer;
        img {
          width: 50%;
        }
      }
      .recorder-box-loading {
        background: #DAEAFF;
        .loading-taste-line {
          position: absolute;
          width: 40px;
          height: 40px;
        }
        .item{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          animation: xuanzhuan 4s linear infinite;
        }
        .item:nth-child(1){
          animation-delay: 0.15s;
        }
        .item:nth-child(2){
          animation-delay: 0.3s;
        }
        .item:nth-child(3){
          animation-delay: 0.45s;
        }
        .item:nth-child(4){
          animation-delay: 0.6s;
        }
        .item:nth-child(5){
          animation-delay: 0.75s;
        }
        .item::after{
          content:'';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: royalblue;
        }
        @keyframes xuanzhuan{
          75%{
            transform: rotate(650deg);
          }
          79%{
            transform: rotate(720deg);
            opacity: 1;
          }
          80%{
            transform: rotate(720deg);
            opacity: 0;
          }
          100%{
            transform: rotate(810deg);
            opacity: 0;
          }
        }
      }
      .recorder-box-stop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 80px;
        .recorder-stop {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          margin: 0 10px;
          border-radius: 100%;
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
          cursor: pointer;
          span {
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 3px;
          }
        }
        .start-taste-line {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-taste-line li {
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          width: 4px;
          height: 6px;
          margin: 0 2px;
          display: inline-block;
          border: none;
          border-radius: 1px;
        }
        li {
          animation: note 0.3s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .hr1 {
          animation-delay: -1s;
        }
        .hr2 {
          animation-delay: -0.9s;
        }
        .hr3 {
          animation-delay: -0.8s;
        }
        .hr4 {
          animation-delay: -0.9s;
        }
        .hr5 {
          animation-delay: -1s;
        }
        @keyframes note {
          from {
            transform: scaleY(1);
          }
          to {
            transform: scaleY(5);
          }
        }
      }
    }
  }
  .result-detail {
    width: 100%;
    .result-overall {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 20px;
        color: #1377e1;
        line-height: 25px;
      }
      .play-replay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 50px;
        margin-top: 10px;
        background: #F5F9FF;
        border-radius: 36px;
        span {
          height: 30px;
          line-height: 25px;
          margin-right: 10px;
          font-weight: 500;
          font-size: 36px;
          color: #1377e1;
        }
        img {
          width: 25px;
          cursor: pointer;
        }
      }
    }
    .more-score {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 20px auto;
      .score-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        border-right: 1px solid #d2e8ff;
        .score-name {
          font-weight: 400;
          font-size: 14px;
          color: #333;
        }
        .score-value {

          font-size: 18px;
          color: #1377e1;
        }
      }
      .score-item:last-child {
        border-right: none;
      }
    }
    .answer-item {
      width: 95%;
      margin: 0 auto;
      .part-title {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 22px;
        color: #333;
      }
      ul {
        width: 90%;
        margin: 0 auto;
        li {
          list-style: disc;
          font-weight: 500;
          font-size: 16px;
          color: #1377e1;
        }
      }
    }
  }
}
</style>